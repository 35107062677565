import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-17207db7"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "navigation"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.navData, function (v, k) {
    return _openBlock(), _createElementBlock("span", null, [k != $props.navData.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_createElementVNode("a", {
      href: "javascript:;",
      onClick: function onClick($event) {
        return $options.nextPage(v, k);
      }
    }, _toDisplayString(v.text), 9, _hoisted_3), _createTextVNode(" > ")])) : _createCommentVNode("", true)]);
  }), 256)), _createElementVNode("span", null, _toDisplayString($props.navData[$props.navData.length - 1].text), 1)]);
}
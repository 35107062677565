import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
//图标
import { Search } from '@element-plus/icons-vue';
export default {
  props: ['targetTypeDataList', 'tagList', 'currentActivity'],
  name: 'TagPopup',
  data: function data() {
    return {
      filterTagist: [],
      //标签筛选后的数组
      searchTagData: "",
      //搜索标签
      filterTargetTypeDataList: [],
      activityTagList: [],
      courseData: JSON.parse(localStorage.getItem("course")),
      currentTargetType: {},
      dialogActiveTagVisible: false,
      Search: Search,
      searchData: '',
      currentTagId: ""
    };
  },
  created: function created() {},
  methods: {
    //设置filterTagist
    setFilterTagist: function setFilterTagist(data) {
      this.filterTagist = data;
    },
    //搜索标签
    filterTag: function filterTag() {
      var _this = this;
      // console.log("tagList",this.tagList)
      var filterList = this.tagList.filter(function (item) {
        return item.name.indexOf(_this.searchTagData) > -1;
      });
      this.filterTagist = filterList;
    },
    //搜索维度
    filterTypesTag: function filterTypesTag() {
      var _this2 = this;
      console.log("搜索维度", this.targetTypeDataList);
      var filterList = this.targetTypeDataList.filter(function (item) {
        return item.abbr.indexOf(_this2.searchData) > -1;
      });
      this.filterTargetTypeDataList = filterList;
    },
    //确定
    confirmClick: function confirmClick() {
      var _this3 = this;
      var tags = [];
      for (var i = 0; i < this.activityTagList.length; i++) {
        tags.push(this.activityTagList[i].id || this.activityTagList[i].tagId);
      }
      this.service.axiosUtils({
        requestName: 'setStructureTags',
        data: {
          courseId: this.courseData.id,
          id: this.currentActivity.id,
          tags: tags
        },
        sucFn: function sucFn(res) {
          if (res.status == 1) {
            _this3.$toast.success(res.message);
            _this3.hidePopup();
          }
        }
      });
    },
    //删除指定标签
    delTag: function delTag(v, k) {
      this.activityTagList.splice(k, 1);
    },
    //添加标签
    addTag: function addTag(v) {
      if (!this.checkTag(v)) {
        this.activityTagList.push(v);
      } else {
        this.$toast.error("已存在该标签");
      }
    },
    checkTag: function checkTag(value) {
      for (var i = 0; i < this.activityTagList.length; i++) {
        var currentId = this.activityTagList[i].id || this.activityTagList[i].tagId;
        if (currentId == value.id) {
          return true;
        }
      }
      return false;
    },
    //获取课程活动标签
    getStructureTags: function getStructureTags() {
      var _this4 = this;
      console.log("当前活动", this.currentActivity);
      this.service.axiosUtils({
        requestName: 'getStructureTags',
        data: {
          courseId: this.courseData.id,
          id: this.currentActivityNow.id
        },
        sucFn: function sucFn(res) {
          _this4.activityTagList = res.data[0].tags;
        }
      });
    },
    enter: function enter(event, v) {
      if (this.checkTag(v)) return;
      this.currentTagId = v.id;
      var target = event.target;
      target.style.color = v.tagtype.color;
      /*
       var child=target.querySelectorAll(".left-child")[0];
       target.style.background=v.tagtype.color;
       target.style.borderColor=v.tagtype.color;
       child.style.background="";
       child.style.background="#fff";
       child.style.color=v.tagtype.color;*/
    },
    leave: function leave(event, v) {
      if (this.checkTag(v)) return;
      this.currentTagId = "";
      var target = event.target;
      target.style.color = "#606266";
      /*
      var child=target.querySelectorAll(".left-child")[0];
      target.style.background="#fff";
      target.style.borderColor="#D9D9D9";
      child.style.color="#fff";
      child.style.background=v.tagtype.color;*/
    },
    //切换维度
    changeTargetType: function changeTargetType(item) {
      this.currentTargetType = item;
      //获取当前维度下的所有标签
      this.getTagAll();
    },
    //获取某一维度下的所有标签
    getTagAll: function getTagAll() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: 'tagGetAll',
        data: {
          tagtypeId: this.currentTargetType.id
        },
        sucFn: function sucFn(res) {
          _this5.$parent.setTagList(res.data);
          /* this.$nextTick(()=>{
             var aEle=document.querySelectorAll(".right-div-bottom")[0];
             var aChild=aEle.querySelectorAll(".left-child");
             for (let i = 0; i < aChild.length; i++) {
               aChild[i].style.background=this.currentTargetType.color;
             }
           })*/
        }
      });
    },
    showPopup: function showPopup(aRow) {
      var _this6 = this;
      //显示弹框
      this.dialogActiveTagVisible = true;
      this.currentTargetType = this.targetTypeDataList[0];
      this.currentActivityNow = aRow || this.currentActivity;

      //设置筛选数据
      this.filterTargetTypeDataList = this.targetTypeDataList;
      this.filterTagist = this.tagList;
      //获取课程对应的标签
      this.getStructureTags();
      this.$nextTick(function () {
        _this6.initTagColor();
      });
    },
    //初始化标签的颜色
    initTagColor: function initTagColor() {
      var aEle = document.querySelectorAll(".right-div-bottom")[0];
      var aChild = aEle.querySelectorAll(".left-child");
      for (var i = 0; i < aChild.length; i++) {
        aChild[i].style.background = "";
      }
    },
    hidePopup: function hidePopup() {
      this.dialogActiveTagVisible = false;
      this.activityTagList = [];
    }
  },
  watch: {
    searchData: function searchData(curVal, oldVal) {
      this.filterTypesTag();
    },
    //搜索标签
    searchTagData: function searchTagData(curVal, oldVal) {
      this.filterTag();
    }
  }
};
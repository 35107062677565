import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { Search } from '@element-plus/icons-vue';
export default {
  name: 'CopyStruture',
  props: ['copyCurrentRow'],
  data: function data() {
    return {
      listData: [],
      isCheckedAll: false,
      Search: Search,
      //search图标
      dialogFormVisible: false,
      //显示弹框
      nameFlag: true,
      //新层级名称
      checkedArr: [],
      //选中的数组
      copyName: "",
      //同步的名字
      list: [{
        id: 1,
        name: "阶段二"
      }]
    };
  },
  components: {},
  methods: {
    //选中单个
    checkedItem: function checkedItem(value) {
      //是否全部选中，是的话就把全部选中设置为true
      this.isCheckedAll = this.isCheckedAllFn();
      this.checkedArr = this.getChecked();
    },
    //判断是否是全部选中了
    isCheckedAllFn: function isCheckedAllFn() {
      var isCheckedAll = false;
      for (var i = 0; i < this.listData.length; i++) {
        if (!this.listData[i].isChecked) {
          return isCheckedAll;
        }
      }
      isCheckedAll = true;
      return isCheckedAll;
    },
    //全选中 全不选中
    checkedAll: function checkedAll(value) {
      for (var i = 0; i < this.listData.length; i++) {
        this.listData[i].isChecked = this.isCheckedAll;
      }
    },
    //获取层级的隶属上级
    getParent: function getParent(postData) {
      var _this = this;
      postData.excludePId = this.currentRow.pId;
      this.service.axiosUtils({
        requestName: 'coursestructureGetParent',
        data: postData,
        sucFn: function sucFn(res) {
          // this.structureParentDrop=res.data;
          _this.listData = res.data;
          for (var i = 0; i < _this.listData.length; i++) {
            _this.listData[i].isChecked = false;
          }
        }
      });
    },
    //取消弹框
    cancelDrawer: function cancelDrawer() {
      //关闭弹框
      this.dialogFormVisible = false;
      this.isCheckedAll = false;
      this.nameFlag = true;
      this.checkedArr = [];
    },
    //展示弹框
    showDrawer: function showDrawer(postData, row) {
      this.currentRow = row;
      this.propShowData = postData;
      this.copyName = this.currentRow.name;
      if (postData) {
        this.getParent(postData);
      }
      //展示弹框
      this.dialogFormVisible = true;
    },
    //获取所有选中的父级
    getChecked: function getChecked() {
      var arr = [];
      for (var i = 0; i < this.listData.length; i++) {
        if (this.listData[i].isChecked) {
          arr.push(this.listData[i].id);
        }
      }
      this.checkedArr = arr;
      return arr;
    },
    //确定同步到
    copyCourseStructureTo: function copyCourseStructureTo() {
      var _this2 = this;
      var postData = this.propShowData;
      //获取所有选中的
      postData.structureIds = this.getChecked();
      postData.logo = this.currentRow.logo;
      postData.isClass = this.currentRow.isClass;
      postData.description = this.currentRow.description;
      postData.haveFree = this.currentRow.haveFree;
      postData.recommend = this.currentRow.recommend;
      postData.rank = this.currentRow.rank;
      postData.state = this.currentRow.state;
      if (this.nameFlag) {
        postData.toName = this.copyName;
      } else {
        postData.toName = this.currentRow.name;
      }
      this.service.axiosUtils({
        requestName: "copyCourseStructureTo",
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.$toast.success("同步层级成功");
            _this2.cancelDrawer();
            //更新父级视图
            _this2.$parent.initData();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    }
  }
};
import "core-js/modules/es.function.name.js";
export default {
  name: 'navigation',
  props: ['navData'],
  data: function data() {
    return {};
  },
  created: function created() {
    console.log(this.navData);
  },
  methods: {
    nextPage: function nextPage(v, k) {
      this.$router.push({
        path: v.path,
        name: v.name,
        query: v.query,
        params: v.params
      });
    }
  }
};
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-595e3d0a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "hint"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.isView == 2 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList($props.data.list, function (v, k) {
    return _openBlock(), _createElementBlock("span", null, _toDisplayString(v) + " / ", 1);
  }), 256)) : _createCommentVNode("", true), $data.isView == 1 ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 1
  }, _renderList($props.data.list, function (v, k) {
    return _openBlock(), _createElementBlock("span", null, _toDisplayString(v.text) + " / ", 1);
  }), 256)) : _createCommentVNode("", true)]);
}